import { type TransformerOption } from '@cld-apis/types';
import { useSearchParams } from '@remix-run/react';
import { clsx } from 'clsx';
import { motion, useReducedMotion, type HTMLMotionProps } from 'framer-motion';
import { type LiteYouTube } from 'react-lite-youtube-embed';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { FullScreenYouTubeEmbed, LiteYouTubeEmbed } from '~/components/fullscreen-yt-embed.tsx';
import { Grid } from '~/components/grid.tsx';
import { H2, H3 } from '~/components/typography.tsx';
import { getImgProps, type ImageBuilder } from '~/images.tsx';

export type HeroSectionProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  action?: React.ReactNode;
  as?: React.ElementType;
  rootClassName?: string;
} & (
  | {
      imageProps?: HTMLMotionProps<'img'>;
      imageSize?: 'medium' | 'large' | 'giant';
      image?: never;
      imageBuilder?: never;
      imageTransformations?: never;
    }
  | {
      imageProps?: never;
      imageSize?: never;
      image?: never;
      imageBuilder?: never;
      imageTransformations?: never;
    }
  | {
      imageProps?: never;
      imageSize?: 'medium' | 'large' | 'giant';
      image: React.ReactNode;
      imageBuilder?: never;
      imageTransformations?: never;
    }
  | {
      imageProps?: never;
      imageSize?: 'medium' | 'large' | 'giant';
      image?: never;
      imageBuilder: ImageBuilder;
      imageTransformations?: TransformerOption;
    }
) &
  (
    | {
        arrowUrl: string;
        arrowLabel: string;
      }
    | {
        arrowUrl?: never;
        arrowLabel?: never;
      }
  ) &
  (
    | {
        videoPoster: ImageBuilder;
        videoProps: LiteYouTube;
        videoBackgroundColor?: string;
        videoClassName?: string;
      }
    | {
        videoPoster?: never;
        videoProps?: never;
        videoBackgroundColor?: never;
        videoClassName?: never;
      }
  );

function HeroSection({
  action,
  title,
  subtitle,
  arrowUrl,
  arrowLabel,
  image,
  imageProps,
  imageBuilder,
  imageSize = 'medium',
  as = 'header',
  videoPoster,
  videoProps,
  videoBackgroundColor,
  videoClassName,
  rootClassName
}: HeroSectionProps) {
  const [searchParams] = useSearchParams();
  const hasImage = Boolean(image ?? imageProps ?? imageBuilder);
  const shouldReduceMotion = useReducedMotion();

  const childVariants = {
    initial: { opacity: 0, y: shouldReduceMotion ? 0 : 25 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const videoBgColor = videoBackgroundColor ? videoBackgroundColor : 'bg-red-50 dark:bg-gray-800';

  return (
    <Grid
      as={as}
      rootClassName={clsx(rootClassName)}
      className={clsx('h-auto pt-12 lg:mb-0 lg:min-h-[40rem] lg:pb-8 lg:pt-24', {
        'lg:mb-64': arrowLabel,
        'lg:mb-0': !arrowLabel
      })}
      nested
    >
      {videoProps ? (
        <div
          className={clsx(
            'relative order-last col-span-full mb-12 mt-12 lg:mb-0',
            {
              'px-10 lg:col-span-5 lg:col-start-7': imageSize === 'medium',
              'flex items-center justify-center lg:col-span-5 lg:col-start-8': imageSize === 'large',
              'flex items-center justify-center lg:col-span-7 lg:col-start-6 lg:-mr-5vw lg:-mt-24 lg:px-0':
                imageSize === 'giant'
            },
            videoClassName
          )}
        >
          <div className="absolute flex h-full w-full items-center justify-center px-0 py-0 lg:px-16 lg:py-4">
            <div
              className={clsx('rounded-2xl', videoBgColor, {
                'h-full  w-4/5': imageSize !== 'giant',
                'h-4/5  w-2/5': imageSize === 'giant'
              })}
            />
          </div>
          <FullScreenYouTubeEmbed
            autoplay={searchParams.has('autoplay')}
            img={
              <img
                {...getImgProps(videoPoster, {
                  widths: [256, 550, 700, 900, 1300, 1800],
                  sizes: [
                    '(max-width: 320px) 256px',
                    '(min-width: 321px) and (max-width: 1023px) 80vw',
                    '(min-width: 1024px) 410px',
                    '850px'
                  ]
                })}
                className="rounded-2xl object-cover"
              />
            }
            ytLiteEmbed={
              <LiteYouTubeEmbed
                {...videoProps}
                // We don't show the poster, so we use the lowest-res version
                poster="default"
                params={new URLSearchParams({
                  color: 'white',
                  playsinline: '0',
                  rel: '0'
                }).toString()}
              />
            }
          />
        </div>
      ) : null}
      {hasImage ? (
        <div
          className={clsx('order-last col-span-full mb-12 lg:mb-0', {
            'px-10 lg:col-span-5 lg:col-start-7': imageSize === 'medium',
            'flex items-start justify-center lg:col-span-5 lg:col-start-8': imageSize === 'large',
            'flex items-center justify-center lg:col-span-7 lg:col-start-6 lg:-mr-5vw lg:-mt-24 lg:px-0':
              imageSize === 'giant'
          })}
        >
          {imageProps ? (
            <motion.img
              {...imageProps}
              className={clsx(
                'h-auto w-full object-contain',
                {
                  'max-h-50vh': imageSize === 'medium',
                  'max-h-75vh': imageSize === 'giant'
                },
                imageProps.className
              )}
              initial={{ scale: shouldReduceMotion ? 1 : 1.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.25 }}
            />
          ) : imageBuilder ? (
            <motion.img
              className={clsx('h-auto w-auto ', {
                'max-h-50vh object-contain': imageSize === 'medium',
                'object-contain md:overflow-visible': imageSize === 'large',
                'max-h-75vh object-contain': imageSize === 'giant'
              })}
              {...getHeroImageProps(imageBuilder)}
              initial={{ scale: shouldReduceMotion ? 1 : 1.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.25 }}
            />
          ) : (
            image
          )}
        </div>
      ) : null}

      <div
        className={clsx('col-span-full lg:col-start-1 lg:row-start-1 lg:flex lg:h-full lg:flex-col', {
          'lg:col-span-7': imageSize === 'large',
          'lg:col-span-5': imageSize === 'giant'
        })}
      >
        <motion.div
          className={clsx('flex flex-auto flex-col', {
            'lg:justify-center': hasImage
          })}
          initial="initial"
          animate="visible"
          variants={{
            initial: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
          }}
        >
          <motion.div variants={childVariants}>
            <H2 as="h2" className="text-center lg:text-left">
              {title}
            </H2>
          </motion.div>

          {subtitle ? (
            <motion.div variants={childVariants}>
              <H3 as="p" variant="secondary" className="mt-4 text-center lg:text-left ">
                {subtitle}
              </H3>
            </motion.div>
          ) : null}
          {action ? (
            <motion.div variants={childVariants} className="mt-14 flex flex-col space-y-4">
              {action}
            </motion.div>
          ) : null}
        </motion.div>
        {arrowUrl ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="hidden pt-12 lg:block"
          >
            <ArrowLink to={arrowUrl} direction="down" textSize="small">
              {arrowLabel}
            </ArrowLink>
          </motion.div>
        ) : null}
      </div>
    </Grid>
  );
}

function getHeroImageProps(imageBuilder: ImageBuilder, transformations?: TransformerOption) {
  return getImgProps(imageBuilder, {
    widths: [256, 550, 700, 900, 1300, 1800],
    sizes: [
      '(max-width: 1023px) 80vw',
      '(min-width: 1024px) and (max-width: 1279px) 50vw',
      '(min-width: 1280px) 900px'
    ],
    transformations
  });
}

export { HeroSection, getHeroImageProps };
